import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - मंत्र प्रभाव २" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='मंत्र प्रभाव २'/>
        <div id="main">
          <section id="content" className="main">
            <p>
गण गण गणात बोते । हा मंत्र प्रिय गजाननाते ।< br />
अहर्निष जपला गजानने । अष्टौ सिद्धी लाभल्या त्याने ।< br />
मंत्र जपावा नित्यनेमाने । प्रारब्ध सुसह्य त्याने ।< br />
चमत्कृतीमय होते जीणे । खात्री होते जपता श्रद्धेने ।< br />
कुठच्याही प्रसंगी निर्भीडपणे । तोंड देता सहजपणे ।< br />
चिंता, क्लेश, दारिद्र्य, दुखणे । निवारण मंत्र जपाने ।< br />
मंत्र जपता विविध दर्शने । गजाननाशी संभाषणे ।< br />
श्रद्धेने जपावा प्रत्येकाने । गणामहाराज सांगे स्वानुभवाने ।< br />
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
